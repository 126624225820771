<!--
 * @Author: your name
 * @Date: 2020-09-14 10:09:06
 * @LastEditTime: 2020-11-05 14:00:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xicaishe-wechat\src\App.vue
-->
<template>
  <div id="app" >
    <router-view />
   
  </div>
</template>
<script>

  export default {
    components: {
    
    },
    data() {
      return {
  

      };
    },
    // provide() {
    //   return {
    //     isplays: this.$refs.commonplayer.isplay
    //   }
    // },
    computed: {
    
    },
    created() {

      if (process.env.NODE_ENV === 'development') {
        window.that = this
      }

      // this.$nextTick(() => {
      //   document.body.addEventListener('click', this.bodyClickEvent, false)
      // })
    },
    mounted() {},
    methods: {
      isclose() {
        this.$store.commit('Setiphone', true)
      },
    },
    beforeDestroy() {
    }
  };
</script>