// import wx from "weixin-js-sdk";
// import axios from "axios";
// const shareTitle=document.title;
// const shareDesc=document.title;
// const share={
//     weixinShare(){
//         // let shareTitle = shareTitle;
//         // let shareDesc = shareDesc;
//         // let shareTitle = shareObject.title;
//         // let shareDesc = shareObject.desc;
//         axios
//             .post("/common/getWechatShareParam", {
//                 url: window.location.href,
//             })
//             .then((res) => {
//                 let params = res.data.data;
//                 delete params.url;
//                 // console.log("=====>", {
//                 //   debug: true,
//                 //   ...params,
//                 //   jsApiList: ["updateTimelineShareData"],
//                 // });
//                 wx.config({
//                     debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//                     ...params,
//                     jsApiList: [
//                         "updateTimelineShareData",
//                         "onMenuShareTimeline",
//                         "onMenuShareAppMessage",
//                     ], // 必填，需要使用的JS接口列表
//                 });
//                 wx.ready(function() {
//                     //需在用户可能点击分享按钮前就先调用
//                     wx.onMenuShareTimeline({
//                         title: shareTitle, // 分享标题
//                         link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//                         imgUrl: "http://wechat.xicaishe.com/logo.png", // 分享图标
//                         success: function() {
//                             // 用户确认分享后执行的回调函数
//                             // alert("分享成功");
//                         },
//                         cancel: function() {
//                             // 用户取消分享后执行的回调函数
//                         },
//                     });
//                     wx.onMenuShareAppMessage({
//                         title: shareTitle, // 分享标题
//                         link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//                         imgUrl: "http://wechat.xicaishe.com/logo.png", // 分享图标
//                         desc: shareDesc, // 分享描述
//                         type: "", // 分享类型,music、video或link，不填默认为link
//                         dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
//                         success: function() {
//                             // 用户确认分享后执行的回调函数
//                             // alert("分享成功");
//                         },
//                         cancel: function() {
//                             // 用户取消分享后执行的回调函数
//                         },
//                     });
//                     wx.updateTimelineShareData({
//                         title: shareTitle, // 分享标题
//                         link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
//                         imgUrl: "http://wechat.xicaishe.com/logo.png", // 分享图标
//                         success: function() {
//                             // 设置成功
//                             // alert("分享成功");
//                         },
//                     });
//                 });
//                 wx.error(function(res) {
//                     console.log("JS SDK配置失败===>", res);
//                     // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
//                 });
//             });
//     }
// }

const shareTitle = "淘淘电商";
const shareDesc = "学习财富技能，通达美好未来";
export default {
    shareTitle,
    shareDesc,
    setShareTitle(shareTitle){
        this.shareTitle=shareTitle;
    },
    setShareDesc(shareDesc){
        this.shareDesc=shareDesc;
    }
};