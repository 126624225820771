/*
 * @Author: your name
 * @Date: 2020-09-14 10:09:07
 * @LastEditTime: 2020-11-05 13:58:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xicaishe-wechat\src\libs\const.js
 */
// 线上环境


// let  API =  'https://testappapi.xicaishe.com';


// let API='http://192.168.11.3:9080'

// let API
// //测试环境
// 
    let API ='https://api.chengmingxt.com/app-api';
   
// switch (process.env.NODE_ENV) {
//     case "test":
//         API =  'https://testappapi.xicaishe.com';
//         break;
//     case "development":
//         API =  'https://testappapi.xicaishe.com';
//         break;
//     case "production":
//         API =  'https://appapi.xicaishe.com';
//     break;
//     default:
//         API = 'https://appapi.xicaishe.com';
//         break;
// }
// // // 以下是测试环境
// export {API};
export {API};









