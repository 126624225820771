/* author: 李栓
 * date:2018-05-04
 * axios请求封装
 * 主要封装方法 get,post,put,delete方法
 */
import axios from "axios";
import tools from "./utils";
import md5 from "js-md5";
import { Base64 } from "js-base64";
import  { API } from './const'
import store from '../store/index' 
// let URL = "http://xuetang.surfuns.com"; // 统一url
let URL = API // 统一url
let baseURL =
  process.env.NODE_ENV == "production" ? `${URL}/api` : `${URL}/api`;
axios.defaults.baseURL = baseURL;
import { Toast } from "vant";

//post方法：
const post = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
//get方法：
// 8497f207f84048f0a830de7105ec06a4
const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
const del = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
const put = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(url, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
const patch = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// 请求拦截器
// multipart/form-data
axios.interceptors.request.use(
  function(config) {
    config.headers["content-type"] = "application/json";
    config.headers["appType"] = "H5";
    config.headers["token"] = tools.getToken();
    config.headers["signature"] = sign(
      config.data,
      parseInt(+new Date() / 1000)
    );    
    config.headers["appId"] = store.state.APPID;

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  function(res) {
    function urlencode (str) {  
      // alert(str)
      str = (str + '').toString();   
  
      return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').  
      replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');  
  }
  // urlencode(myurl)

  // console.log(res.data.code,'============')
    if (res.data.code !== 1017 && res.data.code !== 0 && res.data.code !== 1050  && res.data.code !== 1067 && res.data.code !== 200)  {
      if(res.data.code !== 1057){
        Toast({message:res.data.msg,
          duration:2000});
      }
   
    }
  
    // 
    if (res.data.code === 1017 || res.data.code === 1004) {
      //登录过期
      console.log("token已过期==》", res.data);
      getotken()
    }
   
    if(res.data.code === 1067){
      store.commit('Setiphone',false)
     }
    return res;
  },
  err => {
    //服务器出错
    return Promise.reject(err);
  }
);
var iTime;
// var _this=this
function getotken(){
  clearTimeout(iTime);
  iTime=setTimeout(()=>{
    tools.removeToken("token");
    tools.removeToken("userInfo");
    // var myurl=``
    let appId=store.state.APPID
    // console.log(location.href.replace('?jiause2=0',''),)
// console.log(window.location.pathname,'===')
    axios.post(`/login/getWechatLoginData`, {
      appId:appId,
      locationHref:window.location.pathname=='/activity'?location.href+'&jiause2=0:':location.href
      // ${location.href}&jiause2=0:"
    }).then(res => {
        console.log(res,'---')
        // return
      // let myurl=urlencode(`${URL}/api/login/wechatAuth?data=${Base64.encode(location.href)}`)
      tools.setToken(res.data.data.token)
      if(tools.getToken()!=''){
        location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          appId
        }&redirect_uri=${res.data.data.redirectUri}&response_type=code&scope=snsapi_userinfo&state=123&connect_redirect=1#wechat_redirect`;
      }
    });
    console.log('调用啦')
  },250);
}
// header签名
const sign = (body, timestamp) => {
  let salt = "0896fde5o98u28d996321753rd98d9569548";
  // console.log(salt)
  let token = tools.getToken();
  // token逻辑
  let str = "";
  // body && Object.keys(body).sort().forEach(key => str += key + body[key])
  body &&
    Object.keys(body)
      .sort()
      .forEach(key => {
        if (typeof body[key] != "undefined") {
          str += key + body[key];
        }
      });
  str = str.toString();
  // console.log(str)
  if (str) {
    str = str
      .replace(new RegExp("\\[", "g"), "")
      .replace(new RegExp("]", "g"), "")
      .replace(new RegExp('"', "g"), "")
      .replace("inviteCodeundefined", "")
      .replace("packageIdundefined", "");
    // str = str.replaceAll("\\[", "").replaceAll("]", "").replaceAll("\"", "");
  }
  // let beforeMd5 = Base64.encode(String(timestamp)) + (token || "") + salt + str;
  str = str.replace("inviteCodeundefined", "");
  console.log("signPlain---" + str);
  let beforeMd5 = Base64.encode(token + salt + str);
  // console.log('sign---' + beforeMd5)
  let sign = md5(beforeMd5);
  // console.log('sign:' + sign)
  return sign;
};

export { post, get, del, put, patch };
