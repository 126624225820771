import Vue from "vue";
import {
  Swipe,
  SwipeItem,
  Icon,
  Cell,
  CellGroup,
  Card,
  Popup,
  Slider,
  Overlay,
  RadioGroup,
  Radio,
  Collapse,
  CollapseItem,
  Col,
  Checkbox,
  CheckboxGroup,
  Row,
  Toast,
  Field,
  Lazyload,
  Tab,
  Tabs,
  Sticky,
  Form,
  Button,
  NoticeBar,
  Loading,Step, Steps
} from "vant";


import "vant/lib/swipe/style";
import "vant/lib/swipe-item/style";
import "vant/lib/icon/style";
import "vant/lib/cell/style";
import "vant/lib/cell-group/style";
import "vant/lib/card/style";
import "vant/lib/popup/style";
import "vant/lib/slider/style";
import "vant/lib/overlay/style";
import "vant/lib/radio-group/style";
import "vant/lib/radio/style";
import "vant/lib/collapse/style";
import "vant/lib/collapse-item/style";
import "vant/lib/col/style";
import "vant/lib/row/style";
import "vant/lib/checkbox/style";
import "vant/lib/checkbox-group/style";
import "vant/lib/toast/style";
import "vant/lib/field/style";
import "vant/lib/tab/style";
import "vant/lib/tabs/style";
import "vant/lib/sticky/style";

Vue.use(Step);
Vue.use(Steps);
Vue.use(Loading)
Vue.use(Swipe);
Vue.use(NoticeBar);
Vue.use(Form);
Vue.use(Button);
Vue.use(SwipeItem);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Card);
Vue.use(Popup);
Vue.use(Slider);
Vue.use(Overlay);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Lazyload, {
  lazyComponent: true
});
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Sticky)
