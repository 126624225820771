import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "@/views/home/routerpage";
// import courseChildren from "./children/course";
// import userChildren from "./children/user";
// import appChildren from "./children/app";
// import circleChildren from "./children/circle";
// import coms from './children/coms'
import { API } from '@/libs/const'
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "study",
    meta: {
      title: "提交个人信息",
      // keepAlive: true // 需要被缓存
    },
    component: () => import(/*webpackChunkName: "study"*/ "@/views/home/index"),
  },
  {
    path: "/concat",
    name: "concat",
    meta: {
      title: "签署合同",
      // keepAlive: true // 需要被缓存
    },
    component: () => import(/*webpackChunkName: "study"*/ "@/views/contract/concat"),
  },
  {
    path: "/contractok",
    name: "concat",
    meta: {
      title: "签署成功",
      // keepAlive: true // 需要被缓存
    },
    component: () => import(/*webpackChunkName: "study"*/ "@/views/contract/contractok"),
  },
  

  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const payRouterAccess = [
  '/course/sureOrder',
  '/course/newsurebuy',
  '/course/sharePreferential',
  '/course/sharePreferentialLimit',
  '/course/newsharePreferentialLimit',
  // '/activity',
  '/course/purchase',
  '/course/purchaselive'
]
// 创建元素
function CreateElm(elName, content,attr,src) {
  let elm = document.createElement(elName);
  // elm.innerHTML = content;
  elm.id = attr;
  elm.src = src;
  elm.type="text/javascript";
  elm.charset-'utf-8'
  return elm;
}
router.beforeEach((to, from, next) => {
  if(to.path == '/course/resultok'){
    Vue.nextTick(() => {
      let head = document.getElementsByTagName('head')[0];
      let scriptText = `
    (window,document,'script', );
      `
      head.appendChild(CreateElm('script',scriptText,'script_el','https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js'));
    })
  }
  document.title = to.meta.title;
  window.scrollTo(0, 0);

  let ua = window.navigator.userAgent.toLowerCase()
  let isWeixin = ua.match(/MicroMessenger/i) == 'micromessenger'

  if (payRouterAccess.includes(to.path)) {
    let oid = to.query.alipay
    let appid = to.query.appid
    console.log(to.query);
    let type = to.query.type;
    if (oid && !isWeixin) {
      if(to.path == '/course/purchase' && type == 'alipay'){ // 支付宝
        return window.location.href = API + `/api/pay/zfbH5?orderId=${oid}&appId=${appid}`;  
          // return window.location.href='www.baidu.com'
      }
      if(to.path == '/course/purchase' && type == 'yinlian'){  // 银联
        return window.location.href = API + '/api/pay/zfbH54Union?orderId=' + oid ;
      }
      return window.location.href = API + '/xtapi/order/alipay?orderId=' + oid
    }
  }

  // 如果是未登录，并且是从其他页面转跳过来的，进入登录页面
  // if (!Vue.cookie.get('xcsat') && from.name) {
  //   return window.location.href = '/login'
  // }

  next()
});
router.afterEach(() => {});
export default router;
