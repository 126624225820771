/**
 * 过滤器
 **/

export const format = (date, format = 'YYYY-MM-dd hh:mm:ss') => {
  if (typeof (date) === "string")
    date = new Date(date.replace(/-/g, "/"));//处理苹果时间格式兼容性的问题
  let o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  for (let time in o) {
    if (isNaN(o[time])) {
      return "";
    }
  }
  if (/(y+)/.test(format))
    format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let k in o)
    if (new RegExp("(" + k + ")").test(format))
      format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return format;
};
