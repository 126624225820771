import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./libs/vant";
import "./scss/common.scss";
import "./scss/reset.scss";
import "./scss/animate.css";
import "./libs/rem";
import * as filters from "./libs/filter";
import tools from "./libs/utils";
import Cookie from './libs/cookie'
import axios from "axios";
import wx from "weixin-js-sdk";
import global from "./libs/global";
// import fundebugVue from "fundebug-vue";
import qs from 'qs'
import clipboard from 'clipboard';
import Meta from 'vue-meta'
// import {}
// import {reportLearnDuration} from '@/api/newapi'

Vue.use(Meta)

// import Vant from 'vant';
// import 'vant/lib/index.css';
// Vue.use(Vant)

// import "pdfh5/css/pdfh5.css";
// import APlayer from '@moefe/vue-aplayer';



// fundebug.apikey = "991c255fe7906aeabea57f9c5c22c7c24996f9b8a8bcfb6810e0dc7d57e430dd"
if (process.env.NODE_ENV === 'production') {
  // fundebugVue(fundebug, Vue);
} else {
  const VConsole = require('vconsole')
  Vue.prototype.vConsole = new VConsole()
}
// document.addEventListener('visibilitychange', function() {
//                   if(document.hidden) {
//                          this.$store.commit("PLAY", true);
//                     console.log("关屏后继续播放");
//                   } else {
//                        this.$store.commit("PLAY", true);
//                       console.log("页面激活， 继续播放")
//                   }
//               })
// import vConsole from "vconsole";
// new vConsole();
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
Vue.config.productionTip = false;
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
//注册到vue原型上
Vue.prototype.clipboard = clipboard;
Vue.prototype.$tools = tools;
Vue.prototype.$share = global;

Vue.use(Cookie)

const ua = navigator.userAgent
Vue.prototype.$isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");



// Vue.prototype.$wxShare = wxShare;
//去掉分享链接中的token，防止用户信息错乱
function clearShareUrlToken(shareUrl) {
  var url = shareUrl.replace("&token=", "&userTk=")
          .replace("?token=", "?userTk=")
          .replace("%26token%3D", "%26userTk%3D")
          .replace("%3Ftoken%3D", "%3FuserTk%3D");
  return url;
}
