/**
 * 公共方法
 * author lints
 * date 2019-04-18
 */

// import constant from "@/config/constant.conf";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import { get } from "./axios.package";
import { stringify } from "qs";
// import {reportLearnDuration} from '@/api/newapi'

// const {
//   cookieExpires,
//   version
// } = constant;
const tools = {
  /**
   * 获取token
   * @return token or false
   * */
  getToken() {
    return Cookies.get("token");
  },
  /**
   * 删除token
   * @return token or false
   * */
  removeToken(key) {
    // userInfo token
    return Cookies.remove(key);
  },
  //设置用户信息
  setUserInfo(userInfo) {
    return Cookies.set("userInfo", JSON.stringify(userInfo), {
      expires: 1 //用户信息缓存1天
    });
  },
  // function getSession(key) {
  //   return sessionStorage[key] ? JSON.parse(sessionStorage[key]) : "";
  // }
  
  // function setSession(key, palyload) {
  //   sessionStorage[key] = JSON.stringify(palyload);
  // }
  setUserid(key,palyload){
    // return sessionStorage[key] ? JSON.parse(sessionStorage[key]) : "";
    sessionStorage[key] = JSON.stringify(palyload);
    // return Cookies.set("userid", JSON.stringify(data), {
    //   expires: 1 //用户信息缓存1天
    // });
  },
  //修改文本记录时长的状态
  uppagetimetype(key){
    console.log(this.getpagetime(key),'进行修改type')
    if(this.getpagetime(key)!=''){
      var data=JSON.parse(this.getpagetime(key))
      data.type=1
      localStorage.setItem(key,JSON.stringify(data))
    }
  },
  //存储文本记录的时长
  setpagetime(key,palyload,type,id){
    // if(this.getpagetime(key)!=''){
    //   var data=JSON.parse(this.getpagetime(key))
    //   if(data.type!='1'){
    //     reportLearnDuration({
    //       sectionId:data.id,
    //        type:2,
    //        studyTime:data.time
    //     }).then(res=>{
    //       console.log(res,'上报完成')
    //     })
    //   }
    // }
    var data={
      time:palyload,
      type:type,
      id:id
    }
    console.log(JSON.stringify(data),'===')
    localStorage.setItem(key,JSON.stringify(data))
  },

  //修改音频的上报状态
  upAutio(key,id){
    if(this.getAutio(key)){
       var arr=JSON.parse(this.getAutio(key))
      // arr.forEach(el => {
      //     if(el.id=id){
      //       el.type=1
      //     }
      // });
      localStorage.setItem(key,JSON.stringify(this.Arrayeach2(arr,id)))
    console.log(this.Arrayeach2(arr,id),'修改过状态的音频')
      }
  },
 
  //存储音频的时长 
  setAutioSpeed(key,palyload,id,totalTime){
    if(!id){return}
    var arr=[]
    if(this.getAutio(key)==''){
      arr=[]
    }else{
      if(this.getAutio(key)){
      arr=JSON.parse(this.getAutio(key))
      }
    }
    // console.log(this.Arrayeach(arr,{id:id,currentTime:palyload}))
    localStorage.setItem(key,JSON.stringify(this.Arrayeach(arr,{id:id,currentTime:palyload,type:0,totalTime:totalTime})))
    // console.log(key,palyload,id,'0-0-0-')
    // if(this.getAutio(key)){
    //   var newArr=this.getAutio(key)
    //   var arr = Array.from(newArr)
    //   console.log(arr.length)
    //   console.log(typeof(arr),'===')
    //   // return
    //   if(!id){return}
    //  
    //   //  sessionStorage[key] = 
    // }else{
    //   if(id){
    //     var arr=[]
    //   //  sessionStorage[key] = JSON.stringify(this.Arrayeach(arr,{id:id,currentTime:palyload}));
    //   localStorage.setItem(key,JSON.stringify(this.Arrayeach(arr,{id:id,currentTime:palyload})))
    //     return
    //   }
    //     var arr=[]
    //     arr.push({
    //       id:'111',
    //       currentTime:palyload
    //     })
    //     localStorage.setItem(key,JSON.stringify(arr))
    //     // sessionStorage[key] = JSON.stringify(arr);

    // }
  },

  //she
  Arrayeach(arr,data){
    // console.log(arr,data,'----')
    let newarr=arr
    // let newarr2=[]
     for(var i =0;i<newarr.length;i++){
      if(newarr[i].id==data.id){
        newarr[i].currentTime=data.currentTime
        newarr[i].type=0
        return newarr
      }
    }
    newarr.push(data)
    return newarr
  },


  Arrayeach2(arr,id){
    console.log(arr[0].id,id,'-nbnbn')
    let newarr=arr
    // let newarr2=[]
     for(var i =0;i<newarr.length;i++){
      if(newarr[i].id == id){
        console.log(newarr[i],'[[[[[')
        newarr[i].type=1
        return newarr
      }else{
      //  console.log(newarr[i].id)
      }
    }
    return newarr
  },
   //上报音频获取单个音频的时长进行上报
   getoneAutio(key,id){
      let data=localStorage.getItem(key) || ''
     console.log(id,data,'0000')
      let arrnw=JSON.parse(data)
      if(data!=''){
        var nowdata=''
        arrnw.forEach(item=>{
            if(item.id==id){
              console.log(item,'需要的数据')
              nowdata=item
            }
          })
          return nowdata
      }else{
        return data
      }
   },
  getpagetime(key){
    return localStorage.getItem(key) || ''
  },
  getAutio(key){
    return localStorage.getItem(key) || ''
  },
  getUserid(key,palyload){
    return sessionStorage[key] ? JSON.parse(sessionStorage[key]) : "";   
  },
  getUserInfo() {
    let uinfo = Cookies.get("userInfo")
    if (!uinfo) return false
    return JSON.parse(uinfo);
  },
  /**
   * 设置token
   * @param {string} token - 本地存储token
   * @return {}
   * */
  setToken(token) {
    return Cookies.set("token", token, {
      expires: 1 //用户信息缓存1天
    });
  },
  /**
   * 验证电话号码
   * @param {string or number} val - 电话号码
   * @return true or false
   * */
  validatePhone(value) {
    return /^1[3456789]\d{9}$/.test(value);
  },
  /**
   * 名字校验，支持中文名字2-20位
   * @param {string} val - 名字
   * @return true or false
   * */
  validateName(value) {
    return /^[\u4E00-\u9FA5\uf900-\ufa2d]{2,20}$/.test(value);
  },
  /**
   * 映射对应表
   * @param {array} stateKey - 索引id
   * @param {array} stateVal - 索引值
   * @param {string} type - 需要筛选出来的索引id
   * */
  lookUp(stateKey, stateVal, type) {
    let _level = "";
    for (let i in stateKey) {
      if (stateKey[i] === type) {
        _level = stateVal[i];
        return _level;
      }
    }
    return _level;
  },
  /**
   * 金额验证-只能保留两位小数和整数
   * @param {string,number} val - 值
   * @return true or false
   * */
  validateNumber(val) {
    return /^\d+(\.\d{1,2})?$/.test(val);
  },
  /**
   * ios input 错位问题
   * @param {event} object default
   */
  blur(event) {
    if (
      !(
        event.relatedTarget &&
        (event.relatedTarget.tagName === "INPUT" ||
          event.relatedTarget.tagName === "TEXTAREA")
      )
    )
      return (document.body.scrollTop = 0);
  },
  /**
   * 打印当前版本号
   * @return
   * */
  beautifulConsoleVersion() {
    return console.log(
      "%c当前版本: " + version,
      "color: #04BE02;font-size:22px"
    );
  },
  /**
   * 终端判断
   * @return 'android','iPhone','undefined' 不支持的设备类型
   */
  isJudgeDevice() {
    const u = navigator.userAgent;
    return u.indexOf("Android") > -1
      ? "Android"
      : u.indexOf("iPhone") > -1
      ? "IOS"
      : "undefined";
  },
  /**
   *
   */
  isWeChatBrowser() {
    let ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("micromessenger") != -1;
  },
  /**
   * 判断字符串是否为空
   */
  isEmpty(str) {
    return typeof str == "undefined" || str == null || str == "";
  },
  /**
   * 字符串截取方法
   */
  /**
   * @param {String} url
   * @description 从URL中解析参数
   */
  getParams(url) {
    url = url || window.location.hash;
    if (url.indexOf("?")) {
      var search = url.substring(url.lastIndexOf("?") + 1);
      var obj = {};
      var reg = /([^?&=]+)=([^?&=]*)/g;
      search.replace(reg, function(rs, $1, $2) {
        var name = decodeURIComponent($1);
        var val = decodeURIComponent($2);
        val = String(val);
        obj[name] = val;
        return rs;
      });
    }
    return obj;
  },
  /**
   * 时间转换
   */
  timeFormat(time, format) {
    return dayjs(time).format(format);
  }
};
export default tools;
